import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import Icon from 'components/shared/icon'

import useScrollHide from 'hooks/useScrollHide'
import useScrollPosition from 'hooks/useScroll'
import useLocation from 'hooks/useLocation'

import { scrollToSection } from 'utils/scrollToSection'

import logo from 'assets/icons/logo-1.svg'
import closeIcon from 'assets/icons/close.svg'
import menuWhite from 'assets/icons/menu-white.svg'
import menuDark from 'assets/icons/menu-dark.svg'

import useBreakpoint from 'hooks/useBreakpoint'

type MobileSidebarProps = {
  active?: boolean
}

type NavProps = {
  active?: boolean
  transparent?: boolean
  white?: boolean
}

type MobileNavbarProps = {
  active?: boolean
  white?: boolean
}

type HamburgerButtonProps = {
  active?: boolean
}

const StyledWrapper = styled.div`
  width: 100%;
`

const StyledLogoLink = styled.button<{ primary: boolean }>`
  position: absolute;
  left: 50%;
  top: -21px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  z-index: 4;
  transition: 0.3s;
  line-height: 0;
  pointer-events: none;

  ${({ primary }) =>
    primary &&
    css`
      pointer-events: all;
    `}

  ${({ theme }) => theme.media.lg.min} {
    width: 0;
    height: 0;
    border-left: 135px solid transparent;
    border-right: 135px solid transparent;
    border-top: 200px solid
      ${({ primary, theme }) =>
        primary ? theme.colors.primary : theme.colors.white};

    // Logo Icon
    & > div {
      position: absolute;
      bottom: 90px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      width: 65px;
      height: 65px;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    border-left: 145px solid transparent;
    border-right: 145px solid transparent;
    border-top: 210px solid
      ${({ primary, theme }) =>
        primary ? theme.colors.primary : theme.colors.white};

    // Logo Icon
    & > div {
      position: absolute;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      width: 95px;
      height: 95px;
    }
  }
`

const StyledLogoLinkMobile = styled.button<{ primary: boolean }>`
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  transform: translateX(-50%);
  border-left: 150px solid transparent;
  border-right: 200px solid transparent;
  border-top: 240px solid
    ${({ primary, theme }) =>
      primary ? theme.colors.primary : theme.colors.white};
  z-index: 4;

  // Logo Icon
  & > div {
    position: absolute;
    bottom: 165px;
    left: 0;
    transform: translateX(55%);
    z-index: 5;
    width: 60px;
    height: 60px;
  }

  ${({ theme }) => theme.media.lg.min} {
    display: none;
  }
`

const StyledNavigationMobileSidebar = styled.div<MobileSidebarProps>`
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  top: 0;
  right: 0;
  z-index: 20;
  background: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.media.lg.max} {
    display: flex;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    ${({ active }) =>
      active &&
      css`
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `}
  }
`

const StyledNavigationMobileHeader = styled.header`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledNavigationLinksMobile = styled.div`
  margin: 40px 20px 0 20px;
  display: flex;
  flex-direction: column;
`

const StyledNavigationLinkMobile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};
  text-transform: uppercase;
  text-decoration: none;
`

const StyledMobileNavigationWrapper = styled.div<MobileNavbarProps>`
  display: none;

  ${({ theme }) => theme.media.lg.max} {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 0 40px;
    background-color: ${({ theme, white }) =>
      white ? theme.colors.white : theme.colors.primary};
    box-shadow: 1px 1px 3px 0px ${({ theme }) => theme.colors.dark200};

    transition: 250ms ease transform;

    transform: ${({ active }) =>
      active ? `translate3d(0, 0, 0)` : 'translate3d(0, -250%, 0)'};
  }
`

const StyledMobileLogoLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledNavigationHamburgerButton = styled.button<HamburgerButtonProps>`
  display: none;
  padding: 0;
  background: none;
  border: none;

  ${({ theme }) => theme.media.lg.max} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const FixedNavigationHamburgerButton = styled.button<HamburgerButtonProps>`
  position: fixed;
  top: 40px;
  right: 40px;
  transform: ${({ active }) =>
    active ? `translate3d(0, 0, 0)` : 'translate3d(0, -250%, 0)'};
`

const StyledCloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
`

const StyledLogoWrapper = styled.button`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledDesktopLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: 108px;
  align-items: center;
  padding: 0 30px;
  transition: 0.3s;

  ${({ theme }) => theme.media.lg.max} {
    display: none;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 0 80px;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 16px;
  cursor: pointer;

  ${({ theme }) => theme.media.xl.min} {
    font-size: 18px;
  }
`

const StyledLinksColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  transition: 0.3s;
  max-width: 40%;

  ${({ theme }) => theme.media.xxl.min} {
    // Left-side links
    :first-child {
      padding-right: 20px;
    }

    // Right-side links
    :last-child {
      justify-content: flex-start;
      padding-left: 20px;
      & > ${StyledLink} {
        margin-right: clamp(50px, 4vw, 115px);
      }
    }
  }
`

const StyledNavigationWrapper = styled.nav<NavProps>`
  position: fixed;
  width: 100%;
  z-index: 51;

  background-color: ${({ theme, transparent }) =>
    transparent ? 'none' : theme.colors.primary};

  ${({ white, transparent }) =>
    white &&
    css`
      background-color: ${({ theme }) =>
        transparent ? 'none' : theme.colors.white};
    `}

  transition: 0.6s;

  box-shadow: ${({ transparent }) =>
    transparent ? 'none' : '0 0 20px rgb(0 0 0 / 10%)'};

  transform: ${({ active }) =>
    active ? `translate3d(0, 0, 0)` : 'translate3d(0, -250%, 0)'};

  ${({ transparent }) =>
    !transparent &&
    css`
      ${StyledDesktopLinksWrapper} {
        margin-top: 0;
        height: 98px;
      }

      ${StyledLogoLink} {
        margin-top: 0;
        width: 110px;
      }
    `}
`

const Nav = () => {
  const [active, setActive] = useState(false)

  const isHidden = useScrollHide(0, 150)

  const position = useScrollPosition(10)

  const pathname = useLocation()

  const isHomepage = pathname === '/'

  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <StyledLogoLinkMobile primary={!isHomepage} onClick={() => navigate('/')}>
        <Icon src={logo} size={80} alt="w-dolinie-sanu-logo" />
      </StyledLogoLinkMobile>
      <StyledNavigationWrapper
        active={!isHidden || active}
        transparent={position.y <= 200 && isHomepage}
        white={!isHomepage}
      >
        <StyledDesktopLinksWrapper>
          <StyledLinksColumn>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#o-osiedlu', 'smooth', true)
                  : navigate('/')
              }
            >
              O osiedlu
            </StyledLink>

            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#mieszkania', 'smooth', true)
                  : navigate('/')
              }
            >
              Mieszkania
            </StyledLink>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#okolica', 'smooth', true)
                  : navigate('/')
              }
            >
              Okolica
            </StyledLink>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#lokalizacja', 'smooth', true)
                  : navigate('/')
              }
            >
              Lokalizacja
            </StyledLink>
          </StyledLinksColumn>
          <StyledLogoLink
            primary={!isHomepage}
            onClick={() => (!isHomepage ? navigate('/') : null)}
          >
            <Icon src={logo} size={95} alt="w-dolinie-sanu-logo" />
          </StyledLogoLink>
          <StyledLinksColumn>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#studio-projektowe', 'smooth', true)
                  : navigate('/')
              }
            >
              Studio projektowe
            </StyledLink>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#postep-prac', 'smooth', true)
                  : navigate('/')
              }
            >
              Postęp prac
            </StyledLink>
            <StyledLink
              onClick={() =>
                isHomepage
                  ? scrollToSection('#kontakt', 'smooth', true)
                  : navigate('/')
              }
            >
              Kontakt
            </StyledLink>
          </StyledLinksColumn>
        </StyledDesktopLinksWrapper>
        <StyledNavigationMobileSidebar active={active}>
          <StyledNavigationMobileHeader>
            <StyledLogoWrapper>
              <Icon src={logo} size={105} alt="w-dolinie-sanu-logo" />
            </StyledLogoWrapper>
          </StyledNavigationMobileHeader>
          <StyledNavigationLinksMobile onClick={() => setActive(false)}>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#o-osiedlu', 'smooth', true)
                  : navigate('/')
              }
            >
              O osiedlu
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#mieszkania', 'smooth', true)
                  : navigate('/')
              }
            >
              Mieszkania
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#okolica', 'smooth', true)
                  : navigate('/')
              }
            >
              Okolica
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#lokalizacja', 'smooth', true)
                  : navigate('/')
              }
            >
              Lokalizacja
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#studio-projektowe', 'smooth', true)
                  : navigate('/')
              }
            >
              Studio projektowe
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#postep-prac', 'smooth', true)
                  : navigate('/')
              }
            >
              Postęp prac
            </StyledNavigationLinkMobile>
            <StyledNavigationLinkMobile
              onClick={() =>
                isHomepage
                  ? scrollToSection('#kontakt', 'smooth', true)
                  : navigate('/')
              }
            >
              Kontakt
            </StyledNavigationLinkMobile>
          </StyledNavigationLinksMobile>
          <StyledCloseButton
            onClick={() => {
              setActive(false)
            }}
            aria-label="close"
          >
            <Icon src={closeIcon} width={22} alt="close" />
          </StyledCloseButton>
        </StyledNavigationMobileSidebar>
        <StyledMobileNavigationWrapper
          active={!isHidden && position.y > 0}
          white={!isHomepage}
        >
          <StyledMobileLogoLink onClick={() => navigate('/')}>
            <Icon src={logo} size={50} alt="w-dolinie-sanu-logo" />
          </StyledMobileLogoLink>
          <StyledNavigationHamburgerButton
            active={!isHidden}
            onClick={() => {
              setActive(true)
            }}
          >
            <Icon
              src={isHomepage ? menuWhite : menuDark}
              size={40}
              alt="menu"
            />
          </StyledNavigationHamburgerButton>
        </StyledMobileNavigationWrapper>
        <FixedNavigationHamburgerButton
          active={!lg && position.y === 0}
          onClick={() => {
            setActive(true)
          }}
        >
          <Icon src={isHomepage ? menuWhite : menuDark} size={40} alt="menu" />
        </FixedNavigationHamburgerButton>
      </StyledNavigationWrapper>
    </StyledWrapper>
  )
}

export default Nav
